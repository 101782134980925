import React from 'react';
import axios from 'axios';

import { config } from '../../config';
import { ReactComponent as StarIcon } from '../../assets/images/star-icon.svg';
import { ReactComponent as Check } from '../../assets/images/check.svg';
import { ReactComponent as QuestionIcon } from '../../assets/images/questions.svg';
import { ReactComponent as CantSeeBillImage } from '../../assets/images/cant-see-bill.svg';
import { NYBOptionTitles } from './constants';
import Loader from './components/Loader';
import {
  $NYBContainer,
  $NYBContent,
  $NYBHeader,
  $NYBOption,
  $NYBOptionBlock,
  $Radio,
  $NYBControls,
  $NYBControlItem,
  $InfoText,
  $Center,
  $SVG,
} from './NeedHelp.styled';

const NeedHelp = ({
  onClose,
  openFeedback,
  readonlyFeedback,
  billUid,
  setNYBStatus,
  hideFeedBack,
}) => {
  const [activeStep, setActiveStep] = React.useState(null);
  const [radioInput, setRadioInput] = React.useState(0);
  const [isLoading, setIsLoading] = React.useState(false);
  const radioRef = React.useRef();

  const onNextClick = (e, step) => {
    if (radioInput === 3) {
      onClose();
      if (!readonlyFeedback) {
        openFeedback();
      }
    }
    setActiveStep(step || radioInput);

    switch (radioInput) {
      case 1:
        setRadioInput(5);
        break;
      case 2:
        setRadioInput(4);
        break;
      default:
        break;
    }
  };

  const deleteBill = async () => {
    if (isLoading) return null;
    setIsLoading(true);
    try {
      await axios.delete(`${config?.host}/bill/${billUid}`, {
        data: {
          notYourBillType: 'wrongNumber',
        },
      });
    } catch (error) {
      throw new Error(error);
    } finally {
      setIsLoading(false);
    }
  };

  const _getOptionIndex = () => {
    const index = NYBOptionTitles.findIndex(({ step }) => step === activeStep);
    return index;
  };

  return (
    <$NYBContainer>
      <$NYBContent>
        <$NYBHeader>
          {activeStep || activeStep === 0 ? (
            <p>{NYBOptionTitles[_getOptionIndex()]?.title}</p>
          ) : (
            <$SVG width="40px">
              <QuestionIcon />
            </$SVG>
          )}
        </$NYBHeader>
        {!activeStep && activeStep === null ? (
          <>
            <$NYBOptionBlock>
              {NYBOptionTitles.filter(
                ({ option, feedback }) => option && (feedback ? feedback === !hideFeedBack : true),
              ).map(({ title, step }) => (
                <$NYBOption key={step}>
                  <Radio
                    label={title}
                    value={step}
                    name="radio"
                    forwardRef={radioRef}
                    active={step === radioInput}
                    onChange={setRadioInput}
                  />
                </$NYBOption>
              ))}
            </$NYBOptionBlock>
            <$NYBControls>
              <$NYBControlItem onClick={onClose} className="cancel">
                Cancel
              </$NYBControlItem>
              <$NYBControlItem onClick={onNextClick} className="next">
                Next
              </$NYBControlItem>
            </$NYBControls>
          </>
        ) : (
          populateStepperContent(activeStep, {
            onClose,
            onNextClick,
            radioRef,
            radioInput,
            setRadioInput,
            openFeedback,
            readonlyFeedback,
            isLoading,
            deleteBill,
            setNYBStatus,
            hideFeedBack,
          })
        )}
      </$NYBContent>
    </$NYBContainer>
  );
};

export default NeedHelp;

const Radio = ({ onChange, value, label, active, name, forwardRef }) => {
  return (
    <$Radio onClick={onChange ? () => onChange(value) : () => {}}>
      <input type="radio" value={value} checked={active} id={value} name={name} ref={forwardRef} />
      <label htmlFor={value}> {label}</label>
    </$Radio>
  );
};

const populateStepperContent = (
  step,
  {
    onClose,
    onNextClick,
    radioRef,
    radioInput,
    setRadioInput,
    openFeedback,
    readonlyFeedback,
    isLoading,
    deleteBill,
    setNYBStatus,
    hideFeedBack,
  },
) => {
  const handleNextClick = async (lastStep, forDelete, forFeedback, e) => {
    if (lastStep) {
      onClose();
      if (step === 6) {
        window.location.reload();
      }
    } else if (forDelete) {
      try {
        await deleteBill();
        onNextClick(e, step === 5 ? 6 : null);
      } catch (error) {
        onClose();
        console.error(error);
      }
    } else {
      onNextClick();
    }

    if (forFeedback && !readonlyFeedback) {
      setNYBStatus('wrongItems');
      openFeedback();
    }
  };

  const StepControls = ({
    forDelete,
    nextText = 'Next',
    forFeedback = false,
    cancelText = 'Cancel',
    lastStep = false,
  }) => (
    <$NYBControls>
      {!lastStep && (
        <$NYBControlItem onClick={onClose} className="cancel">
          {cancelText}
        </$NYBControlItem>
      )}
      <$NYBControlItem
        onClick={(e) => handleNextClick(lastStep, forDelete, forFeedback, e)}
        className={forDelete ? 'delete' : lastStep ? 'next last-step' : 'next'}>
        {nextText}
      </$NYBControlItem>
    </$NYBControls>
  );

  switch (step) {
    case 0:
      return (
        <$Center>
          <$SVG width="100%">
            <CantSeeBillImage />
          </$SVG>
          <$InfoText>
            Click on the view more button to view your bill. If you still cant see the bill after
            clicking the view more, please leave a feedback.
          </$InfoText>
          <StepControls lastStep={true} />
        </$Center>
      );
    case 1:
    case 2:
      return (
        <>
          <$NYBOptionBlock>
            {NYBOptionTitles.filter(
              ({ option, feedback }) =>
                option === false && (feedback ? feedback === !hideFeedBack : true),
            ).map(({ title, step }) => (
              <$NYBOption key={step}>
                <Radio
                  label={title}
                  value={step}
                  name="radio"
                  forwardRef={radioRef}
                  active={step === radioInput}
                  onChange={setRadioInput}
                />
              </$NYBOption>
            ))}
          </$NYBOptionBlock>
          <StepControls />
        </>
      );
    case 4:
      return (
        <>
          <$Center>
            <$SVG>
              <StarIcon />
            </$SVG>
            <$InfoText>Please leave a feedback of the problem you are facing.</$InfoText>
          </$Center>
          <StepControls nextText="Proceed" forFeedback lastStep />
        </>
      );
    case 5:
      return (
        <>
          <$Center>
            <$InfoText>Are you sure this is not your bill,</$InfoText>
            <$InfoText>we will delete this bill for your number.</$InfoText>
            {isLoading ? <Loader /> : null}
          </$Center>
          <StepControls nextText="Delete Bill" forDelete />
        </>
      );

    case 6:
      return (
        <>
          <$Center>
            <$SVG>
              <Check />
            </$SVG>
            <div>
              <$InfoText>We have deleted the bill,</$InfoText>
              <$InfoText>Sorry for the inconvenience.</$InfoText>
            </div>
          </$Center>
          <StepControls nextText="Okay" lastStep />
        </>
      );
    default:
      return null;
  }
};
