import React from 'react';
import styled from 'styled-components';
import BillMeHorizontalLogo from '../../assets/images/billme-blue-horizontal.svg';
import BillMeWhiteLogo from '../../assets/images/billme-white-horizontal.svg';
import RZPBMLogoDark from '../../assets/images/logos/rzpbm-logo-dark.svg';
import RZPBMLogoLight from '../../assets/images/logos/rzpbm-logo-Light.svg';

const $PoweredByBranding = styled.div`
  text-align: center;
  padding: ${(props) => props.theme.spacing.xl} 0;
  background-color: ${({ bgColor }) => bgColor || '#fff'};

  p {
    font-family: ${({ fontFamily }) => fontFamily || 'Roboto, sans-serif'};
    margin: 0px 0px ${(props) => props.theme.spacing.s};
    font-weight: ${({ fontWeight }) => fontWeight || 400};
    font-size: ${({ fontSize }) => fontSize || '14px'};
    color: ${({ textColor }) => textColor || '#fff'};
  }
`;

export const PoweredByBranding = ({
  textColor,
  bgColor,
  fontSize,
  fontWeight,
  isWhiteLogo,
  fontFamily,
  isMiraj,
  isConnplex,
  isNyCinemas,
  brandId,
  companyId,
}) => {
  const classNameConfiguration = {
    [isMiraj]: 'miraj-footer-digital',
    [isConnplex]: 'connplex-digital',
    [isNyCinemas]: 'feedbackratingadjust',
  };

  return (
    <$PoweredByBranding
      bgColor={bgColor}
      textColor={textColor}
      fontSize={fontSize}
      fontWeight={fontWeight}
      fontFamily={fontFamily}
      className={classNameConfiguration[companyId || brandId ? true : false] || 'media-no-print'}>
      <p>Digital billing powered by</p>
      <a target="_blank" rel="noopener" href="https://billme.co.in">
        <img src={isWhiteLogo ? RZPBMLogoDark : RZPBMLogoLight} width="150px" alt="BillMe Logo" />
      </a>
    </$PoweredByBranding>
  );
};
