import styled, { css } from 'styled-components';
import BmLogo from '../../assets/images/bill-me-horizontal.svg';
import BKLogo from '../../assets/images/burger-king/bk-new-logo.svg';
import { BillMaxWidth } from '../constants';

const getFooterBg = (props) => {
  if (props.isDevyani) return 'transparent';
  else if (props.isBK) return '#6D3D2F';
  else if (props.isNewFooter) return '#fff';
  else return '#38a1e3';
};

export const StyledFooter = styled.div`
  z-index: 21;
  width: 100%;
  height: 70px;
  max-width: ${BillMaxWidth};
  background: ${(props) => getFooterBg(props)};
  margin-top: 5%;
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  grid-auto-rows: minmax(100%, auto);
  align-items: center;
  position: ${(props) => props.position};
  box-shadow: ${(props) => (props.isDevyani ? 'none' : '-1px -1px 5px -2px #000000')};
  bottom: 0;
  overflow: hidden;
  background-color: ${(props) => props.backgroundcolor};

  ${({ isNewFooter }) =>
    isNewFooter
      ? css`
          box-shadow: ${(props) =>
            props.isDevyani ? 'none' : '-1px -1px 5px 4px hsla(0, 0%, 30%, 0.2)'};

          .billme-logo {
            background: url(${(props) => (props.isBK ? BKLogo : '')}) 0 0% no-repeat;
            height: ${(props) => (props.isBK ? '42px' : 'auto')};
          }
        `
      : css`
          .billme-logo {
            background: url(${(props) => (props.isBK ? BKLogo : BmLogo)}) 0 0% no-repeat;
            height: ${(props) => (props.isBK ? '42px' : '20px')};
          }
        `};

  .billme-logo {
    width: 60px;
    grid-column: 1 / 1;
    background-size: contain;
    margin: 0px 15px;
    margin-right: 0;

    img {
      width: 100%;
      height: auto;
    }
  }
  .billme-logo-hdfc {
    width: 110px;
    grid-column: 1 / 1;
    background-size: contain;
    margin: 0px 15px;
    margin-right: 0;
    height: 27px;

    img {
      width: 120%;
      height: 27px;
    }
  }
  .billme-logo-bewakoof {
    width: 110px;
    grid-column: 1 / 1;
    background-size: contain;
    margin: 0px 15px;
    margin-right: 0;
    height: 27px;

    img {
      width: 115%;
      height: 27px;
    }
  }
  span.bk {
    color: #fff;
    font-weight: 700;
    font-size: 16px;
    white-space: nowrap;
    font-family: 'Block-Berthold', sans-serif;
  }

  .meta-text {
    white-space: nowrap;
    font-weight: 500;
    margin-left: 8px;
    font-size: 14px;
    text-decoration: none;
    color: #043040;
  }

  .footer-body {
    grid-column: 3 / 6;
    text-align: right;
    padding-right: 15px;

    .download-btn {
      color: #fff;
      background: ${({ btnColor }) => btnColor || '#00c800'};
      font-family: 'Roboto', sans-serif;
      font-weight: 400;
      font-size: 1em;
      border: 0;
      outline: none;
      cursor: pointer;
      padding: 5px 15px;
      border-radius: 10px;
      text-decoration: none;

      ${({ isNewFooter }) =>
        isNewFooter &&
        css`
          border-radius: 4px;
        `};
    }
  }
  @media print {
    display: none;
  }
`;

export const BkDownloadButton = styled.button`
  &&& {
    font-family: 'Block-Berthold', sans-serif;
    color: #6d3d2f;
    background: #fff;
    font-weight: 700;
    font-size: 16px;
  }
`;
export const BestSellerDownloadButton = styled.button`
  &&& {
    background: #00268a;
  }
`;
export const HdfcDownloadButton = styled.button`
  &&& {
    background: #0496c7;
  }
`;
export const MahavirVisitButton = styled.button`
  &&& {
    background: #f2b743;
    color: black;
  }
`;
