import React from 'react';

export const bgCampaign = (campaignData, parsedData) => {
  const campBgDetails = campaignData;
  let backgroundImage = '';
  let top = '';
  let backgroundSize = '';
  let index = campBgDetails.findIndex((o) => o.type === 'billBackground');

  if (index > -1) {
    backgroundImage = campBgDetails[index]?.assetsDetails[0].fileLocation;
    top = campBgDetails[index]?.assetsDetails[0]?.customStyles?.spacings?.top;
    backgroundSize = campBgDetails[index]?.assetsDetails[0]?.customStyles?.backgroundSize;
  }

  return { backgroundImage, top, backgroundSize };
};
