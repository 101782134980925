import React from 'react';
import { campaignTypes } from '../../campaigns/constants';
import { GlobalHTMLStyle, $POBButton } from '../../../css/styles';
import { $NYBContent, $NYBContainer, $POBCloseButton } from '../../NeedHelp/NeedHelp.styled';
import { CampaignType } from '../../campaigns/CampaignType';
import CloseRedIcon from '../../../assets/images/close-red.svg';
const PopOverBill = (props) => {
  const {
    campaignDetailsPOB,
    campaignDetails,
    coupons,
    surveyData,
    afterSurvey,
    billUid,
    handlePOBClick,
    isPopupOverBillModalOpen,
    POBSetting,
  } = props;
  const handlePOBButtonClick = (e) => {
    e.preventDefault();

    if (!!campaignDetailsPOB?.setting?.btnDetails?.link) {
      let wind = window.open(campaignDetailsPOB?.setting?.btnDetails?.link, '_blank');
      wind.focus();
    }
  };
  if (!isPopupOverBillModalOpen) return null;
  return (
    <>
      <GlobalHTMLStyle />
      <$NYBContainer className="media-no-print">
        <$NYBContent>
          {POBSetting?.closeBtnDelay === 0 && (
            <$POBCloseButton onClick={handlePOBClick}>
              <img src={CloseRedIcon} alr="Close button red colour" />
            </$POBCloseButton>
          )}
          <CampaignType
            type={campaignTypes.popupOverBill}
            // showVaccineBanner={}
            coupons={coupons}
            campaignDetails={campaignDetails}
            surveyData={surveyData}
            afterSurvey={afterSurvey}
            billUid={billUid}
            isButtonPresent={!!campaignDetailsPOB?.setting?.btnDetails?.txt}
            flags={props.flags}
            campaignDetailsPOB={campaignDetailsPOB}
            handlePOBButtonClick={handlePOBButtonClick}
          />
        </$NYBContent>
      </$NYBContainer>
    </>
  );
};

export default PopOverBill;
