const DEV = {
  host: 'http://localhost:4001',
  billUrl: 'http://localhost:3000/',
};

const TEST = {
  host: 'https://billapp-development.stage.yourbill.me',
  billUrl: 'https://test.yourbill.me/',
};

const PROD = {
  host: 'https://api.yourbill.me',
  billUrl: 'https://yourbill.me/',
};

const MIGRATION = {
  host: 'https://billapp-backend-ts.stage.billme.io',
  billUrl: 'https://migration.yourbill.me/',
};

export const pizzaHutSurvey = 'http://s.pizzahutsurvey.com/IND';

export function tacoBellSurvey() {
  return `https://s.tacobellsurvey.com/IND?CN=BH${arguments[1]}&V=V`;
}

const configArr = { TEST, PROD, MIGRATION };

export const config = process.env.REACT_APP_ENV ? configArr[process.env.REACT_APP_ENV] : DEV;
