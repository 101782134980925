import React, { forwardRef } from 'react';
import { useTheme } from 'styled-components';
import { SocialTop, SocialBillMeBrands, F6Socials } from '../../social/Social';
import { BestSellerSocial } from '../../social/BestSellerSocial.jsx';
import { MarcaSocial } from '../../social/MarcaSocial';
import { DecathlonSocial } from '../../social/DecathlonSocial';
import { Le15Online, Le15Social } from '../../social/Le15Social';
import { TrentSocialList } from '../../social/TrentSocials';
import { DownloadBill } from '../../DownloadBill';
import AppleIcon from 'assets/images/apple-logo.svg';
import GplayIcon from 'assets/images/google-play-iconn.svg';
import {
  GlobalHTMLStyle,
  $Flexbox,
  $CineIndoDownloadBtn,
  $CineIndoFlexbox,
  $CineIndoTextA,
  $CineIndoTextB,
  $MirajButton,
  $PrasadsTnCContent,
  $TertiaryButton,
  MediaPrintStyle,
  Dash,
} from '../../../css/styles';
import NeedHelp from '../../NeedHelp';
import { $NYBContent, $NYBContainer, $POBCloseButton } from '../../NeedHelp/NeedHelp.styled';
import CloseRedIcon from '../../../assets/images/close-red.svg';
import { MovieMaxIconsSet } from '../../social/MovieMaxSocial.jsx';
import { MenMomsIconsSet } from '../../social/MenMoms.jsx';
import { JoshhSocialTop } from 'components/social/JoshhRestaurant';
import { ValueSocial } from 'components/social/ValueSocial';
import { CCDSocial } from 'components/social/CCDSocial';
import { HaltSocial } from 'components/social/HaltSocial';
import { HastimalManikchandSocial } from 'components/social/HastimalManikchandSocial';
import { InventisSocial } from 'components/social/InventisSocial';
import { BeautynBeyondSocial } from 'components/social/BeautynBeyondSocial';
import CoussouqSocial from 'components/social/Coussouq';
import { ReactComponent as InstagramBW } from 'assets/images/instagram-logo-bw.svg';
import { $RRTerms } from '../../templates/styles';
import { VENDOR_META } from 'components/constants';
import { SocialGlobal } from 'components/social/SocialGlobal';

const TnCContent = (props) => {
  const {
    parsedData,
    isCinepolisGroup,
    isMovieMaxTicket,
    isRelaxo,
    isConnplex,
    isMiraj,
    isSamoh,
    isZudio,
    multiCoupons,
    isRoongta,
    isNyCinemas,
    isKaya,
    isBewakoof,
    isSuperK,
  } = props;
  const getFoodVoucherTnc = () => {
    return parsedData?.customData.ticketType === 'CINEPOLIS_FOOD' || multiCoupons ? (
      <>
        <li>Food voucher can be redeemed for any food/ beverage</li>
        <li>Food Voucher is valid until 4 hours after the show start</li>
        <li>Food voucher cannot be combined with any other offer</li>
        <li>Food Voucher is valid only at PopCorner (Cafe) Counter for redemption</li>
        <li>
          Food Voucher Coupon Code needs to be presented to Miraj Staff at PopCorner (Cafe) Counter
          for redemption
        </li>
      </>
    ) : null;
  };

  const getMirajMovieTnC = () => {
    return parsedData?.customData.ticketType !== 'CINEPOLIS_FOOD' ? (
      <>
        <li>Outside Food and Beverage is not allowed inside the cinema premises.</li>
        <li>Ticket required for child 3 years and above.</li>
        <li>
          Ticket for ``A`` rated movie should not be purchased for people under 18 years of age.
          There won`t be a refund for tickets booked in such cases.
        </li>
        <li>
          Ticket once purchased cannot be exchanged or adjusted/transferred for any other show.
        </li>
        <li>
          Handbags, Laptops, Tabs, cameras and all other electronic items are not allowed inside
          cinema premises.
        </li>
        <li>
          Smoking is strictly not permitted inside the cinema premises. Cigarettes, lighters,
          matchsticks, Gutkha, Pan masala etc. will not be allowed.
        </li>
        <li>
          People under the influence of Alcohol and Drugs will not be allowed inside the cinema
          premises.
        </li>
        <li>
          Items like laptop, cameras, knives, lighter, match box , cigarettes, firearms and all
          types of inflammable objects are strictly prohibited.
        </li>
        <li>
          Items like carry-bags eatables , helmets , handbags are not allowed inside the theaters
          and are strictly prohibited.
        </li>
        <li>For 3D movies, ticket price includes charges towards usage of 3D glasses.</li>
        <li>Incase the ticket is lost or misplaced, duplicate ticket will not be issued.</li>
        <li>Cinema Reserves the Right of Admission.</li>
        <li>Decision(s) taken by Miraj management is final & abiding.</li>
      </>
    ) : null;
  };

  if (isSuperK) {
    return (
      <p style={{ textDecoration: 'none', fontWeight: '550', fontSize: '17px' }}>
        This is a computer generated invoice. No signature is required.
      </p>
    );
  }

  if (isKaya) {
    return (
      <ol
        style={{
          fontFamily: 'Roboto',
          fontSize: '13px',
          margin: '6px 5px -10px -10px',
          lineHeight: '1.40',
        }}>
        <li style={{ marginBottom: '15px' }}>
          {' '}
          The Client agrees that the Services are booked with free consent of the Client. The Client
          is informed and understands that the clinical results of Services vary from person to
          person; or depends upon the regularity and frequency in attending the prescribed services;
          following up as advised; or prompt follow up in case of any adverse outcome; individual’s
          health condition including allergies, medical history, skin type and response of body;
          self-care and precautions; compliance of instructions given by Doctor at Kaya and
          therefore Client shall not raise any claim against Kaya including for results or adverse
          events.
        </li>
        <li style={{ marginBottom: '15px' }}>
          {' '}
          Refunds are not allowed on Services & Products. Exchange in case of defective product
          should be done within 14 days of purchase with a valid copy of the purchase invoice at the
          clinic of purchase.
        </li>
        <li style={{ marginBottom: '15px' }}>
          {' '}
          Services have to be utilized strictly within the package expiry period.{' '}
        </li>
        <li style={{ marginBottom: '15px' }}>
          {' '}
          Balance payment of the services is payable on or before the consumption/scheduled payment
          of the client’s pending sessions.
        </li>
        <li style={{ marginBottom: '15px' }}>
          {' '}
          Kaya Clinic reserves the right to alter/modify any terms and conditions at any point of
          time without any reason or intimation whatsoever.
        </li>
        <li style={{ marginBottom: '15px' }}>
          {' '}
          In case of any dispute, the same shall be subject to Mumbai jurisdiction.
        </li>
        <li style={{ marginBottom: '15px' }}>
          {' '}
          For future reference retain your invoice/advance receipt /proforma invoice.
        </li>
        <li style={{ marginBottom: '30px' }}>
          E-Consultation T&C Please click here{' '}
          <a target="_blank" href="https://bit.ly/2AEtEGX">
            {' '}
            https://bit.ly/2AEtEGX{' '}
          </a>
          .
        </li>
      </ol>
    );
  }
  if (isCinepolisGroup) {
    return (
      <>
        <section className="service-note">
          <h4>COVID-19 Safety Rules:</h4>

          <ul>
            <li>Masks are always mandatory in the cinemas unless eating.</li>
            <li>Social distancing will be maintained between seats.</li>
            <li>You are advised to show the Aarogya Setu App at the cinema entrance.</li>
            <li>Temperature Screening will be performed at the entrance.</li>
            <li>Sanitizers are available for patrons.</li>
          </ul>
        </section>
        <section className="service-note">
          <h4>Rules and Regulations:</h4>

          <ul>
            <li>
              Following Items Are Strictly Prohibited : Handbags, Carry Bags, Mobile Charger,
              Electronic items, Helmets, Bottles, Tiffin Box, Outside Eatables (Including Guthka,
              Pan-masala),Outside Beverages, Chewing Gum, Lighter, Match Box, Cigarette, Firearms &
              All kind of inflammable objects etc
            </li>
            <li>
              Pricing of the tickets as shown against the show-timing are all inclusive admission
              prices.
            </li>
            <li>
              A non-refundable 3D glass usage fee of Rs. 30 per ticket would be charged per ticket
              for all 3D shows.
            </li>
            <li>The Cinema Management reserves the rights of admission.</li>
            <li>
              For ‘A’ rated content, patrons below 18 years of age will not be allowed to enter.
            </li>
            <li>Please go through the emergency evacuation plan of the cinema & the auditorium.</li>
            <li>Tickets once sold are non-transferable & non-refundable.</li>
            <li>Please check detail on the ticket & your balance before leaving the counter.</li>
            <li>Children above 3 years will be charged full ticket.</li>
            <li>
              Visit our website at{' '}
              <a href="https://www.cinepolisindia.com/" target="_blank" rel="noopener noreferrer">
                www.cinepolisindia.com
              </a>
            </li>
          </ul>
          <section className="cinepolis-meta">
            <p>{parsedData?.customData?.pos}</p>
            <p>{parsedData?.customData?.qrString}</p>
          </section>
        </section>
      </>
    );
  } else if (isConnplex) {
    return (
      <>
        <p>{'Terms & Conditions'}</p>
        <ul>
          <li>
            1. The viewer below the age of 18 years cannot be admitted for movies certified as A
            rated.
          </li>
          <li>2. Entry in the Audi will be given before 5 minutes of the showtime.</li>
          <li>3. Tickets are mandatory for children aged 3 years and above.</li>
          <li>4. The ticket is only valid for the date and the show printed on the ticket.</li>
          <li>5. The ticket is neither Transferable nor Refundable.</li>
          <li>6. Rights of admission are reserved.</li>
          <li>
            7. Management will not allow the viewer to enter/ carry any stuff from outside (eatable/
            drink) except one small water bottle per viewer.
          </li>
          <li>
            8. Items like Laptop bags, Baggage, food packages, cameras, cigarettes/ e-cigarettes/
            bidi, knives, firearms, lighters/ matchboxes, and all types of inflammable objects are
            strictly restricted.
          </li>
          <li>
            9. Only online booking partner messages are allowed, printouts & forwarded messages are
            allowed for both Movies Tickets and F&B.
          </li>
          <li>
            10. If there is any show breakdown or cancellation due to technical reasons, money will
            be refunded according to the booking process. Online booking will get a refund online
            and not at the theatre.
          </li>
          <li>
            11. A minimum of 6 tickets is required to run the show, or the show will be cancelled.
          </li>
        </ul>
      </>
    );
  } else if (isRoongta) {
    return (
      <>
        <p>{'Rules & Regulations'}</p>
        <ul>
          <li>
            Following items Are Strictly Prohibited: Handbags, Carry Bags, Mobile Charger,
            Electronics items, Helmets, Bottles, Tiffin Box, Outside Eatables (Including Guthka,
            Pan- Masala, Outside Beverages, Chewing Gum, Lighter Match Box, Cigarette, Firearms &
            All kind of inflammable objects etc.
          </li>
          <li>
            Pricing of the tickets as shown against the show- timing are all inclusive admission,
            Prices.
          </li>
          <li>
            A non-refundable 3D glass usage fee of Rs. 30 per ticket would be Charged per ticket for
            all 3D shows.
          </li>
          <li>The Cinema Management reserves the rights of admission.</li>
          <li>For ‘A’ rated content patrons below 18 years of age Will not be allowed to enter.</li>
          <li>Please go through the emergency evacuation plan of the Cinema & the Auditorium.</li>
          <li>Tickets once sold are non- transferable & non- refundable.</li>
          <li>Please check details on the ticket & your balance before leaving the counter.</li>
          <li>Children above 3 years Will be charged full ticket.</li>
        </ul>
      </>
    );
  } else if (isMovieMaxTicket) {
    return (
      <>
        <p>COVID-19 Safety Rules:</p>
        <ul>
          <li>Masks are always mandatory in the cinemas unless eating.</li>
          <li>Social distancing will be maintained between seats.</li>
          <li>You are advised to show the Aarogya Setu App at the cinema entrance.</li>
          <li>
            Temperature Screening will be performed at the entrance. Guests with-high temperature (`
            {'>'}` 99.2 F) will be prohibited from entering the cinema and full ticket amount will
            be refunded.
          </li>
          <li>Sanitizers are available for patrons.</li>
        </ul>
        <p>Rules and Regulations:</p>
        <li>Outside foods are not allowed in cinema premises.</li>
        <li>
          Following Items Are Strictly Prohibited: Handbags, Carry Bags, Mobile Charger, Electronic
          items, Helmets, Bottles, Tiffin Box, Outside Eatables (Including Guthka, Pan-masala),
          Outside Beverages, Chewing Gum, Lighter, Match Box, Cigarette, Firearms & All kind of
          inflammable objects etc
        </li>
        <li>
          Pricing of the tickets as shown against the show-timing are all inclusive admission
          prices.
        </li>
        <li>
          A non-refundable 3D glass usage fee of Rs. 30 per ticket would be charged per ticket for
          all 3D shows.
        </li>
        <li>The Cinema Management reserves the rights of admission.</li>
        <li>For ‘A’ rated content, patrons below 18 years of age will not be allowed to enter.</li>
        <li>Please go through the emergency evacuation plan of the cinema & the auditorium.</li>
        <li>Tickets once sold are non-transferable & non-refundable.</li>
        <li>Please check detail on the ticket & your balance before leaving the counter.</li>
        <li>Children above 3 years will be charged full ticket.</li>
        <li>
          Visit our website at&nbsp;
          <a href="https://www.moviemax.co.in/" target="_blank" rel="noopener noreferrer">
            www.moviemax.co.in
          </a>
        </li>
      </>
    );
  } else if (isRelaxo) {
    return (
      <div style={{ width: '100%' }}>
        <img
          width={'100%'}
          src="https://assets.billme.co.in/relaxo-assets/relaxo-term-and-condition.webp"
        />
      </div>
    );
  } else if (isMiraj) {
    return (
      <>
        <p>Terms & Conditions</p>
        <ul>
          {parsedData?.customData.ticketType !== 'CINEPOLIS_FOOD' && getMirajMovieTnC()}
          {getFoodVoucherTnc()}
        </ul>
      </>
    );
  } else if (isBewakoof) {
    return (
      <ul
        style={{
          fontFamily: 'Roboto',
          fontSize: '13px',
          margin: '6px 5px -10px -10px',
          lineHeight: '1.40',
        }}>
        <li style={{ marginBottom: '15px' }}>
          We will accept exchanges within 15 days of purchase, only if the Sales Invoice is
          presented along with the merchandise.
        </li>
        <li style={{ marginBottom: '15px' }}>
          {' '}
          ⁠All merchandise which needs to be exchanged should be in its original condition along
          with the barcode tags intact.
        </li>
        <li style={{ marginBottom: '15px' }}>
          {' '}
          Altered Merchandise, Inner Wear and Masks cannot be exchanged or returned.
        </li>
        {/* <Dash color="hsla(0, 0%, 0%, 0.5)" dashLength="100%" /> */}
        <li style={{ marginBottom: '15px' }}> Please follow inner wash care label instructions.</li>
        <li style={{ marginBottom: '15px' }}>
          {' '}
          We reserve all rights to determine whether merchandise is damaged or used.
        </li>
        <p style={{ textAlign: 'center' }}>Exchange Policy</p>
        <li style={{ marginBottom: '15px' }}>
          {' '}
          If you are not delighted with your purchase, we will be happy to exchange your product
          within 15 days from the date of purchase. The sales Invoice must mandatorily be presented
          at the time of exchange, along with the intact product and original tags.
        </li>
        <li style={{ marginBottom: '15px' }}>
          {' '}
          ⁠For feedback and complaints, please write to us at:
          <a target="_blank" href="https://bit.ly/2AEtEGX">
            {' '}
            care@bewakoof.com{' '}
          </a>
          .
        </li>
      </ul>
    );
  } else if (isSamoh) {
    return (
      <div style={{ textAlign: 'center' }}>
        <p>Eligibility for Exchange/Returns</p>
        <p>
          Products purchased once can be returned or exchanged within three weeks (21 days) from the
          date of purchase. Ensure the product is unused and in the same condition as when
          purchased. During return/exchange, please provide the original invoice, the product’s
          original packaging and price tags. In case of returns, a credit note of the ‘spent amount’
          will be issued to you. The credit note will be valid for six (6) months from the date of
          issue and redeemable at any of our stores pan-India.
        </p>
        <p>
          Customised products and purchases made during sales/discounts are not eligible for returns
          or exchanges.
        </p>
        <p>Home, jewellery, and accessories are also not applicable for refund/return/exchange.</p>
        <Dash color="hsla(0, 0%, 0%, 0.5)" dashLength="100%" />
        <p>
          Furthermore, all returns are subject to quality checks from our end. (Just to be safe!)
        </p>
        <p>
          Once the return is confirmed, you will be offered an exchange or credit note as per your
          order eligibility.
        </p>
        <p>
          PLEASE NOTE: Returns initiated after 3 weeks (21 days) from the date of purchase will not
          be entertained.
        </p>
      </div>
    );
  } else if (isZudio) {
    return (
      <div style={{ textAlign: 'center' }}>
        <p>
          Trent Limited
          <br />
          Zudio - Unit Of Tent Ltd.
          <br />
          Regd. Office:
          <br />
          Bombay House, 24 Homi Mody Street,
          <br />
          Mumbai - 400001, 27 - Maharashtra
          <br />
          CIN: L24240MH1952PLC008951
          <br />
          GST IN: 27AAACL1838J1ZG
          <br />
        </p>
        <Dash color="hsla(0, 0%, 0%, 0.5)" dashLength="100%" />
        <p style={{ fontWeight: 800, fontStyle: 'italic', fontSize: '22px' }}>
          Irresistible Fashion
          <br />
          Irresistible Prices
        </p>
        <p>
          www.zudio.com |{' '}
          <InstagramBW style={{ width: '20px', height: '14px', marginBottom: '-2px' }} /> @myzudio
        </p>
        <Dash color="hsla(0, 0%, 0%, 0.5)" dashLength="100%" />
        <p>
          <div style={{ fontWeight: 800, fontStyle: 'italic', fontSize: '22px' }}>
            If you are not happy,
            <br />
            we're not happy.
          </div>
          Just ask for an exchange across any Zudio store
        </p>
        <p>
          <div style={{ fontWeight: 600, fontStyle: 'italic', fontSize: '16px' }}>
            If you have an invoice
          </div>
          We will give you an exchange or complete credit as per the invoice value
        </p>
        <p>
          <div style={{ fontWeight: 600, fontStyle: 'italic', fontSize: '16px' }}>
            If you do not have an invoice
          </div>
          We will give you a credit note as per the current selling price mentioned in the system
        </p>
        <p>
          *T&C apply. No exchange or refund on cosmetics & mens and womens briefs. Exceptions only
          for faulty product.
        </p>
      </div>
    );
  } else if (isNyCinemas) {
    return (
      <>
        <ol>
          {VENDOR_META.NyCinemas.tnc.map((tnc, index) => (
            <li key={index}>{tnc}</li>
          ))}
        </ol>
      </>
    );
  } else {
    return (
      <>
        <p>COVID-19 Safety Rules:</p>
        <ul>
          <li>Masks are always mandatory in the cinemas unless eating.</li>
          <li>You are advised to show the Arogya Setu App at the cinema entrance.</li>
          <li>
            Temperature Screening will be performed at the entrance. Guests with-high temperature (
            {'>'} 99.2 F) will be prohibited from entering the cinema and full ticket amount will be
            refunded.
          </li>
          <li>Sanitizers are available for patrons</li>
        </ul>
        <p>Please Note:</p>
        <ul>
          <li>
            Following Items Are Strictly Prohibited: Handbags, Carry Bags, Mobile Charger,
            Electronic items, Helmets, Bottles, Tiffin Box, Outside Eatables (Including Guthka,
            Pan-masala), Outside Beverages, Chewing Gum, Lighter, Match Box, Cigarette, Firearms &
            All kind of inflammable objects etc.
          </li>
          <li>
            Pricing of the tickets as shown against the show-timing are all inclusive admission
            prices.
          </li>
          <li>
            A non-refundable 3D glass usage fee of Rs. 20 per ticket would be charged per ticket for
            all 3D shows.
          </li>
          <li>The Cinema Management reserves the rights of admission.</li>
          <li>
            For ‘A’ rated content, patrons below 18 years of age will not be allowed to enter.
          </li>
          <li>Tickets once sold are non-transferable & non-refundable.</li>
          <li>Please check detail on the ticket & your balance before leaving the counter.</li>
          <li>Children above 3 years will be charged full ticket.</li>
          <li>Out side Foods is strictly Prohibited.</li>
          <li>For Online booking Visit www.prasadz.com</li>
        </ul>
      </>
    );
  }
};

const SocialsTopRow = forwardRef((props, ref) => {
  const {
    flags,
    storeId,
    parsedData,
    isNYBModalOpen,
    setIsNYBModalOpen,
    handleRatingClick,
    feedbackExist,
    billUid,
    setNYBStatusFeedback,
    deletedBill,
    isTnCModalOpen,
    setIsTnCModalOpen,
    handleDownloadBillClick,
    isBkDownloadBillButtonDisabled,
  } = props;
  const {
    showSocial,
    isDecathlonIndiaEcom,
    isCCD,
    isMarcaEcomm,
    isBestSeller,
    isBillMeStore,
    isCentroGrande,
    isMiraj,
    hideFeedBack,
    isCinepolisGroup,
    isCinepolisTicket,
    isNYBRow,
    showTnCModal,
    isBK,
    isNykaaEcom,
    isLE15,
    isMovieMaxTicket,
    isMeNMoms,
    isDeluxCaterers,
    isValuePlusRetail,
    isRelaxo,
    isNuttysDen,
    isHalt,
    isCarnival,
    isInventis,
    isHastimalManikchand,
    isBeautynBeyond,
    isConnplex,
    isCoussouq,
    isF6Fashion,
    isF6FashionWomen,
    isZudio,
    isSamoh,
    isStarbazzar,
    isTrent,
    isMisbu,
    isRoongta,
    isBodyBuildingIndia,
    isHdfChargeSlip,
    isUtsa,
    isNyCinemas,
    isSuperK,
    isDemoWestside,
    isRzpPos,
    isDemoCinepolis,
    isDecathIndPlay,
    isKaya,
    isMulmul,
    isMahavirStore,
    isCinepolisIndonesia,
    isBewakoof,
  } = flags;
  const theme = useTheme();

  const multiCoupons = props?.coupons?.multiCoupons || null;

  const handleNYBModal = () => setIsNYBModalOpen(!isNYBModalOpen);
  const handleTnCModal = (e) => {
    e?.preventDefault();
    const params = new URLSearchParams(window.location.search);
    params.delete('showModal');
    const url = `${window.location.protocol}//${window.location.host}${window.location.pathname}${params?.toString()?.length ? '?' + params.toString() : ''}`;
    window.history.replaceState({}, document.title, url);
    setIsTnCModalOpen(!isTnCModalOpen);
  };

  const hideNotYourBill = isNuttysDen;

  const andriod = {
    superk:
      'https://play.google.com/store/apps/details?id=in.localbuy.superk.customer.superk_customer',
    cineIndo: 'https://play.google.com/store/apps/details?id=com.cinemaxx.activity&pli=1',
    tmrwBrands: 'https://play.google.com/store/apps/details?id=com.bewakoof.bewakoof&hl=en&pli=1',
  };
  const ios = {
    superk: 'https://apps.apple.com/us/app/superk/id6479734742',
    cineIndo: 'https://apps.apple.com/in/app/cin%C3%A9polis-cinemas-indonesia/id986733797',
    tmrwBrands: 'https://apps.apple.com/in/app/bewakoof-fashion-shopping-app/id1100190514',
  };

  const downloadAppBtnCineIndo = (type, flag) => {
    if (type === 'android') {
      window.open(andriod[flag], '_blank');
    }
    if (type === 'ios') {
      window.open(ios[flag], '_blank');
    }
  };

  const tertiaryButtonStyleforShowTnC = () => {
    return {
      ...(isCinepolisTicket ? { background: '#E7E7E8' } : {}),
      ...(isMiraj ? { background: 'white', fontWeight: 'bolder', color: 'black' } : {}),
      ...(isNyCinemas ? { fontWeight: 'bolder', color: '#013B49' } : {}),
      ...(isKaya ? { cursor: 'pointer', color: 'black', fontWeight: '600' } : {}),
      ...(isBewakoof ? { cursor: 'pointer', color: 'black', fontWeight: '600' } : {}),
    };
  };
  const tertiaryButtonStyle = () => {
    return {
      ...(isNykaaEcom ? { color: theme.color.nykaa } : {}),
      ...(isCinepolisTicket ? { background: '#E7E7E8' } : {}),
      ...(isMiraj ? { background: 'white', fontWeight: 'bolder', color: 'black' } : {}),
      ...(isNyCinemas ? { fontWeight: 'bolder', color: '#013B49', width: 'auto' } : {}),
      ...(isKaya ? { fontWeight: '600', color: 'black' } : {}),
      cursor: 'pointer',
    };
  };
  const tertiaryTmrwButtonStyle = () => {
    return {
      ...{ cursor: 'pointer', color: 'black', fontWeight: '600' },
    };
  };
  const renderNotYourBill = () => {
    return (
      <>
        {isNYBModalOpen && (
          <>
            <GlobalHTMLStyle />
            <NeedHelp
              onClose={handleNYBModal}
              openFeedback={handleRatingClick}
              readonlyFeedback={feedbackExist}
              hideFeedBack={hideFeedBack}
              billUid={billUid}
              setNYBStatus={setNYBStatusFeedback}
            />
          </>
        )}

        {isTnCModalOpen && (
          <>
            <GlobalHTMLStyle />
            <$NYBContainer>
              <$NYBContent>
                <$POBCloseButton onClick={handleTnCModal}>
                  <img src={CloseRedIcon} alr="Close button red colour" />
                </$POBCloseButton>
                <$PrasadsTnCContent revertStyle={isZudio || isSamoh}>
                  <TnCContent
                    isRelaxo={isRelaxo}
                    isCinepolisGroup={isCinepolisGroup}
                    isMovieMaxTicket={isMovieMaxTicket}
                    parsedData={parsedData}
                    isConnplex={isConnplex}
                    isMiraj={isMiraj}
                    isZudio={isZudio}
                    isSamoh={isSamoh}
                    multiCoupons={multiCoupons}
                    isRoongta={isRoongta}
                    isNyCinemas={isNyCinemas}
                    isKaya={isKaya}
                    isBewakoof={isBewakoof}
                    isSuperK={isSuperK}
                  />
                </$PrasadsTnCContent>
              </$NYBContent>
            </$NYBContainer>
          </>
        )}
        {isConnplex && parsedData?.customData?.ticketType == 'CINEPOLIS_TICKET' && (
          <div>
            <section className="connplex-gst">
              {parsedData?.customData?.cin ? <p>CIN: {parsedData?.customData?.cin}</p> : null}
              <p>
                GSTIN:{' '}
                {`${parsedData?.customData?.gstin} ${
                  parsedData?.customData?.sac ? `/SAC: ${parsedData?.customData?.sac}` : ''
                }`}
              </p>
            </section>
          </div>
        )}

        {isConnplex && (
          <div>
            <h2 class="footerstyl"> #INDIA KA APNA SMART THEATRE </h2>
          </div>
        )}
        <$Flexbox
          width="auto"
          direction={isNYBRow || isDecathIndPlay || isMahavirStore ? 'row' : 'column'}
          alignItems="center"
          justify={isNYBRow || isDecathIndPlay || isMahavirStore ? 'space-between' : 'center'}
          {...(isNuttysDen
            ? { padding: '0' }
            : {
                padding: '16px',
              })}>
          {!isConnplex && showTnCModal && !isKaya && !isSuperK && (
            <$TertiaryButton
              style={tertiaryButtonStyleforShowTnC()}
              isWhiteColor={isCarnival}
              onClick={handleTnCModal}>
              Terms and Conditions
            </$TertiaryButton>
          )}
          {isSuperK && (
            <$TertiaryButton className="footerbtntc" style={tertiaryButtonStyleforShowTnC()}>
              <a
                style={{ color: 'black', cursor: 'pointer' }}
                href="https://www.superk.in/termsofservice"
                target="_blank">
                Terms and Conditions
              </a>
            </$TertiaryButton>
          )}
          {isConnplex && (
            <$TertiaryButton className="footerbtntc" onClick={handleTnCModal}>
              Terms and Conditions
            </$TertiaryButton>
          )}
          {isKaya && (
            <a href="?showModal=tnc">
              <$TertiaryButton style={tertiaryButtonStyleforShowTnC()} onClick={handleTnCModal}>
                Terms & Conditions
              </$TertiaryButton>
            </a>
          )}
          {(isBK ||
            isDecathlonIndiaEcom ||
            isHalt ||
            isBodyBuildingIndia ||
            isDecathIndPlay ||
            isMahavirStore) && (
            <>
              <MediaPrintStyle />
              <$TertiaryButton
                onClick={handleDownloadBillClick}
                className="media-no-print"
                disabled={isBkDownloadBillButtonDisabled}
                style={{ cursor: 'pointer' }}>
                Download Bill
              </$TertiaryButton>
            </>
          )}

          {!hideNotYourBill && !isConnplex && !isRzpPos ? (
            isHdfChargeSlip ? (
              <$RRTerms>
                <a rel="noopener" target="_blank" href="">
                  {' '}
                  Terms and Conditions
                </a>
              </$RRTerms>
            ) : (
              <$TertiaryButton
                isWhiteColor={isCarnival}
                onClick={deletedBill ? () => {} : handleNYBModal}
                className="media-no-print"
                style={isBewakoof ? tertiaryTmrwButtonStyle() : tertiaryButtonStyle()}>
                {isNyCinemas
                  ? ' Not your ticket? Click here'
                  : isSuperK || isKaya || isBewakoof
                    ? ' Not your bill? Click here '
                    : 'Need help? Click here'}
              </$TertiaryButton>
            )
          ) : null}
          {isLE15 ? (
            <>
              <Le15Social />
              <Le15Online />
            </>
          ) : null}
        </$Flexbox>
        {isCinepolisIndonesia ||
          isSuperK ||
          (isBewakoof && (
            <$CineIndoFlexbox>
              <$CineIndoDownloadBtn
                width="auto"
                onClick={() =>
                  downloadAppBtnCineIndo(
                    'android',
                    isCinepolisIndonesia ? 'cineIndo' : isSuperK ? 'superk' : 'tmrwBrands',
                  )
                }
                className="media-no-print"
                style={{
                  ...tertiaryButtonStyle(),
                  width: '200px',
                  height: '50px',
                  backgroundColor: '#000000',
                }}>
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  <img
                    style={{ width: '48px', height: '30px' }}
                    src={GplayIcon}
                    alt="Google Play icon"
                  />
                  <div style={{ textAlign: 'left' }}>
                    <$CineIndoTextA>GET IT ON</$CineIndoTextA>
                    <br />
                    <$CineIndoTextB>
                      <span>Google Play</span>
                    </$CineIndoTextB>
                  </div>
                </div>
              </$CineIndoDownloadBtn>
              <$CineIndoDownloadBtn
                width="auto"
                onClick={() =>
                  downloadAppBtnCineIndo(
                    'ios',
                    isCinepolisIndonesia ? 'cineIndo' : isSuperK ? 'superk' : 'tmrwBrands',
                  )
                }
                className="media-no-print"
                style={{
                  ...tertiaryButtonStyle(),
                  width: '200px',
                  height: '50px',
                  backgroundColor: '#000000',
                }}>
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  <img style={{ width: '50px', height: '30px' }} src={AppleIcon} alt="Apple Icon" />
                  <div style={{ textAlign: 'left' }}>
                    <$CineIndoTextA>Download on the</$CineIndoTextA> <br />
                    <$CineIndoTextB>
                      <span>App store</span>
                    </$CineIndoTextB>
                  </div>
                </div>
              </$CineIndoDownloadBtn>
            </$CineIndoFlexbox>
          ))}
        {isConnplex ? (
          <$TertiaryButton
            onClick={deletedBill ? () => {} : handleNYBModal}
            className="footerbtnnh">
            Need help? Click here
          </$TertiaryButton>
        ) : null}
      </>
    );
  };

  return (
    <>
      {isNykaaEcom && (
        <DownloadBill
          ref={ref}
          customData={parsedData}
          handleDownloadBillClick={handleDownloadBillClick}
        />
      )}
      {renderNotYourBill()}
      {isZudio && <div style={{ textAlign: 'center' }}>Love what's in? Follow to know more</div>}
      {isCoussouq && <CoussouqSocial />}
      {isDeluxCaterers && <JoshhSocialTop />}
      {isValuePlusRetail && <ValueSocial />}
      {showSocial && <SocialTop isCentroGrande={isCentroGrande} />}
      {isDecathlonIndiaEcom && <DecathlonSocial />}
      {isHalt && <HaltSocial />}
      {isCCD && <CCDSocial />}
      {isMarcaEcomm && <MarcaSocial />}
      {isBestSeller && <BestSellerSocial />}
      {isBillMeStore && <SocialBillMeBrands storeId={storeId} />}
      {isMovieMaxTicket && <MovieMaxIconsSet />}
      {isMeNMoms && <MenMomsIconsSet />}
      {isHastimalManikchand && <HastimalManikchandSocial />}
      {isInventis && <InventisSocial />}
      {isBeautynBeyond && <BeautynBeyondSocial />}
      {isF6Fashion && <F6Socials isF6FashionWomen={isF6FashionWomen} />}
      <TrentSocialList
        isSamoh={isSamoh}
        isZudio={isZudio}
        isMisbu={isMisbu}
        isStarbazzar={isStarbazzar}
      />
      {isDemoCinepolis ? <SocialGlobal template={'demoCinepolis'} /> : null}
      {isDemoWestside ? <SocialGlobal template={'westside'} /> : null}
      {isMulmul ? <SocialGlobal template={'mulmul'} /> : null}
    </>
  );
});

export default SocialsTopRow;
